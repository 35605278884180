import styled from "styled-components";

export const Container = styled.div`
  height: calc(100vh - 75px);
  width: 100%;
  display: flex;
  align-items: center;
`;

export const Title = styled.h1`
  font-weight: bold;
  font-size: calc(1.675rem + 1.6vw);
  line-height: calc(1ex / 0.42);
  margin-bottom: 0;
`;

export const SubTitle = styled.h2`
  font-weight: bold;
  font-size: calc(1.675rem + 1.6vw);
  line-height: calc(1ex / 0.42);
  margin-bottom: 2.5rem;
  color: var(--white-2);
`;

export const SubHeader = styled.h3`
  @media screen and (min-width: 48em){
    font-size: calc(1.5rem + 0.25vw);
  }
  font-weight: 500;
`;

export const Text = styled.p`
  @media screen and (min-width: 48em){
    font-size: calc(.35vw + .95em);
  }
  color: var(--white-1);
  font-size: 1.125rem;
  margin-bottom: 0.5rem;
  line-height: calc(1ex / 0.32);
  max-width: 60ch;
`;

export const TextItalic = styled(Text)`
  font-style: italic;
  font-size: 0.875rem !important;
`;