import React from "react";
import { shape, arrayOf, string } from "prop-types";
import { Row, Col } from "react-bootstrap";
import { graphql } from "gatsby";
import styled from "styled-components";
import Layout from "../components/Layout";
import { Container, Text, SubTitle } from "../styles/styles";

const StyledLink = styled.a`
  text-decoration: none;
  background-image: linear-gradient(#0A192F, #0A192F),
    linear-gradient(#7AE7C7, #7AE7C7),
    linear-gradient(#FFF689, #FFF689);
  background-size: 20px 2px, 100% 2px, 0 2px;
  background-position: calc(20px * -1) 100%, 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 2s linear, background-position 2s linear;
  padding-bottom: .5rem;
  &:hover{
    text-decoration: none;
    background-size: 20px 2px, 0 2px, 100% 2px;
    background-position: calc(100% + 20px) 100%, 100% 100%, 0 100%;
  }
  &:focus{
    text-decoration: none;
  }
`;

const contact = ({ data }) => {
  const { description, header, socialMedia } = data.contentfulLayoutContactPage;
    return (
      <Layout>
        <Container>
          <Row>
            <Col xs={12}>
              <SubTitle>{header}</SubTitle>
              <Text>{description}</Text>
              {" "}
              <StyledLink href="#mailgo" data-address="hi" data-domain="bpcodes.com">
                Pop me an email
              </StyledLink>
            </Col>
            <Col xs={12} className="mt-4">
              {socialMedia.map((item) => (
                <a
                  rel="nofollow noopener noreferrer"
                  href={item.url}
                  target="_blank"
                >
                  <img
                    alt={item.name}
                    src={item.icon.file.url}
                    style={{ height: 44, width: 44, marginRight: "12px" }}
                  />
                </a>
              ))}
            </Col>
          </Row>
        </Container>
      </Layout>
    );
};
contact.defaultProps = {
  data: {
    contentfulLayoutContactPage: {
      description: "",
      email: "",
      header: "",
      socialMedia: []
    }
  }
};

contact.propTypes = {
  data: shape({
    contentfulLayoutContactPage: shape({
      description: string,
      email: string,
      header: string,
      socialMedia: arrayOf(shape())
    })
  })
};

export default contact

export const contactQuery = graphql`
  query contactQuery {
    contentfulLayoutContactPage(slug: { eq: "contact" }) {
      email
      description
      header
      socialMedia {
        icon {
          file {
            url
          }
          title
        }
        name
        url
      }
    }
  }
`;